
            @import 'src/styles/mixins.scss';
        
.numberBullet {
    font-size: 50px;
    color: #eef3f8;
    @media screen and (min-width: 769px) {
        font-size: 80px;
    }
}
.txtDesc {
    color: #004483;
    @media screen and (max-width: 769px) {
        font-size: 16px;
        line-height: 1.4;
    }
}
.line {
    font-size: 20px;
    color: #000;
    @media screen and (min-width: 769px) {
        font-size: 40px;
    }
}
.domainName {
    color: #004483;
}
.center {
    max-width: 1206px;
    margin: 0 auto;
}

.block {
    width: 100%;
    // flex-direction: row;

    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-box-shadow: 0 10px 45px -10px rgb(30 30 30 / 20%);
    -moz-box-shadow: 0 10px 45px -10px rgba(30, 30, 30, 0.2);
    box-shadow: 0 10px 45px -10px rgb(30 30 30 / 20%);
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;

    @media screen and (min-width: 769px) {
        // @apply w-64;
        width: 30%;
    }

    &:before {
        display: block;
        content: "";
        position: absolute;
        left: -150px;
        top: -160px;
        width: 300px;
        height: 300px;
        border-radius: 300px;
        background-color: #e3ebf1;
        opacity: 0.5;
    }
}
.blockRow {
    @media screen and (max-width: 1230px) {
        @apply mx-5;
    }
    @media screen and (max-width: 768px) {
        max-width: 340px;
        margin: 0 auto;
    }
}
.blockImg {
    width: 90px;
    position: relative;
    svg {
        width: 100%;
        height: inherit;
    }
    // @media screen and (min-width: 769px) {
    //     width: 90px;
    // }
}
.blockIconSaveCombineImg {
    width: 121px;
}
.blockIconSaveMultipleImg {
    width: 100px;
}
